const PageLine = () => (
    <hr
        style={{
            backgroundColor: 'rgb(232 232 232)',
            border: 'none',
            height: '1px'
        }}
    />
);

export default PageLine;