const API_URL = process.env.NODE_ENV === 'development'
  ? 'http://192.168.9.99/Project-Gomamma/php/'
  : 'https://uatbackoffice.go-mamma.com/php/';

const PATH_URL = 'https://uatbackoffice.go-mamma.com/'; // https://uatbackoffice.go-mamma.com/

const config = {
  API_URL,
  PATH_URL
}

export default config;
